<template>
  <div class="az-body">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "AuthLayout",
  data() {
    return {
      version: ""
    };
  },
  methods: {
    release() {
      this.version = "1.0";
      // this.$accounts.get('/')
      // .then(reply => {
      //     this.version = reply.data.version;
      // })
      // .catch(err => console.log(err))
    }
  }
}
</script>

<style scoped>
</style>
